import React from 'react'

var Test = () => {
    return (
        <div>
            {/* Digmon Channel */}
            <h1>DigMon Channel</h1>
            <a href="https://t.me/DigmonUp">
                https://t.me/DigmonUp
            </a>
            {/* Blum Squad */}
            <h1 style={{color: 'black'}}>Blum Squad</h1>
            <a href="https://t.me/BlumCryptoBot/app?startapp=tribe_blow_win-ref_AkE8HRx1Lg">
                https://t.me/BlumCryptoBot/app?startapp=tribe_blow_win-ref_AkE8HRx1Lg
            </a>
            {/* Do not Enter */}
            <h1 style={{color: 'red'}}>Do Not Enter</h1>
            <a href="https://t.me/+PG_7Ej8-Hg43NmY1">
                https://t.me/+PG_7Ej8-Hg43NmY1
            </a>
            {/* Hamster Kombat */}
            <h1 style={{color: 'orange'}}>Hamster Kombat</h1>
            <h3>Referral</h3>
            <a href='https://t.me/hamster_kombat_bot/start?startapp=kentId6026855291'>
                https://t.me/hamster_kombat_bot/start?startapp=kentId6026855291
            </a>

            {/* Yescoin */}
            <h1 style={{color: 'purple'}}>Yescoin</h1>
            <h3>Referral</h3>
            <a href='https://t.me/theYescoin_bot/Yescoin?startapp=XdPl8a'>
                https://t.me/theYescoin_bot/Yescoin?startapp=XdPl8a
            </a>

            {/* Catizen */}
            <h1 style={{color: '#e18240'}}>Catizen</h1>
            <h3>Referral</h3>
            <a href='https://t.me/catizenbot/gameapp?startapp=rp_2350191'>
                https://t.me/catizenbot/gameapp?startapp=rp_2350191 
            </a>
            <p>
                <spen style={{marginRight: '5px'}}>Club</spen>
                <a href="
            https://t.me/catizenbot/gameapp?startapp=r_2013_2348183
                ">
            https://t.me/catizenbot/gameapp?startapp=r_2013_2348183
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>1</spen>
                <a href="
                https://t.me/catizenbot/gameapp?startapp=rp_2744837
                ">
                    https://t.me/catizenbot/gameapp?startapp=rp_2744837
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>2</spen>
                <a href="
                https://t.me/catizenbot/gameapp?startapp=rp_2744843			
                ">
                    https://t.me/catizenbot/gameapp?startapp=rp_2744843			
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>3</spen>
                <a href="
                https://t.me/catizenbot/gameapp?startapp=rp_2744839			
                ">
                    https://t.me/catizenbot/gameapp?startapp=rp_2744839			
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>4</spen>
                <a href="
                https://t.me/catizenbot/gameapp?startapp=rp_2744841			
                ">
                    https://t.me/catizenbot/gameapp?startapp=rp_2744841			
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>5</spen>
                <a href="
                https://t.me/catizenbot/gameapp?startapp=rp_2744842			
                ">
                    https://t.me/catizenbot/gameapp?startapp=rp_2744842			
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>6</spen>
                <a href="
                https://t.me/catizenbot/gameapp?startapp=rp_2744838			
                ">
                    https://t.me/catizenbot/gameapp?startapp=rp_2744838			
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>7</spen>
                <a href="
                https://t.me/catizenbot/gameapp?startapp=rp_2744860			
                ">
                    https://t.me/catizenbot/gameapp?startapp=rp_2744860			
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>8</spen>
                <a href="
                https://t.me/catizenbot/gameapp?startapp=rp_2744844			
                ">
                    https://t.me/catizenbot/gameapp?startapp=rp_2744844			
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>9</spen>
                <a href="
                https://t.me/catizenbot/gameapp?startapp=rp_2744840			
                ">
                    https://t.me/catizenbot/gameapp?startapp=rp_2744840			
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>10</spen>
                <a href="
                https://t.me/catizenbot/gameapp?startapp=rp_2744861			
                ">
                    https://t.me/catizenbot/gameapp?startapp=rp_2744861			
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>11</spen>
                <a href="
                https://t.me/catizenbot/gameapp?startapp=rp_2745241			
                ">
                    https://t.me/catizenbot/gameapp?startapp=rp_2745241			
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>12</spen>
                <a href="
                https://t.me/catizenbot/gameapp?startapp=rp_2745214			
                ">
                    https://t.me/catizenbot/gameapp?startapp=rp_2745214			
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>13</spen>
                <a href="
                https://t.me/catizenbot/gameapp?startapp=rp_2745215			
                ">
                    https://t.me/catizenbot/gameapp?startapp=rp_2745215			
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>14</spen>
                <a href="
                https://t.me/catizenbot/gameapp?startapp=rp_2745213			
                ">
                    https://t.me/catizenbot/gameapp?startapp=rp_2745213			
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>15</spen>
                <a href="
                https://t.me/catizenbot/gameapp?startapp=rp_2745242			
                ">
                    https://t.me/catizenbot/gameapp?startapp=rp_2745242			
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>16</spen>
                <a href="
https://t.me/catizenbot/gameapp?startapp=rp_2759110
                ">
https://t.me/catizenbot/gameapp?startapp=rp_2759110
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>17</spen>
                <a href="
https://t.me/catizenbot/gameapp?startapp=rp_2777080
                ">
https://t.me/catizenbot/gameapp?startapp=rp_2777080
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>18</spen>
                <a href="
https://t.me/catizenbot/gameapp?startapp=rp_2777189
                ">
https://t.me/catizenbot/gameapp?startapp=rp_2777189
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>19</spen>
                <a href="
https://t.me/catizenbot/gameapp?startapp=rp_2822567
                ">
https://t.me/catizenbot/gameapp?startapp=rp_2822567
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>20</spen>
                <a href="
https://t.me/catizenbot/gameapp?startapp=rp_2988254
                ">
https://t.me/catizenbot/gameapp?startapp=rp_2988254
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>21</spen>
                <a href="
https://t.me/catizenbot/gameapp?startapp=rp_2989733
                ">
https://t.me/catizenbot/gameapp?startapp=rp_2989733
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>22</spen>
                <a href="
https://t.me/catizenbot/gameapp?startapp=rp_2989426
                ">
https://t.me/catizenbot/gameapp?startapp=rp_2989426
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>23</spen>
                <a href="
https://t.me/catizenbot/gameapp?startapp=rp_2989427
                ">
https://t.me/catizenbot/gameapp?startapp=rp_2989427
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>24</spen>
                <a href="
https://t.me/catizenbot/gameapp?startapp=rp_2989429
                ">
https://t.me/catizenbot/gameapp?startapp=rp_2989429
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>25</spen>
                <a href="
https://t.me/catizenbot/gameapp?startapp=rp_2989750
                ">
https://t.me/catizenbot/gameapp?startapp=rp_2989750
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>26</spen>
                <a href="
https://t.me/catizenbot/gameapp?startapp=rp_2989432
                ">
https://t.me/catizenbot/gameapp?startapp=rp_2989432
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>27</spen>
                <a href="
https://t.me/catizenbot/gameapp?startapp=rp_2989428
                ">
https://t.me/catizenbot/gameapp?startapp=rp_2989428
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>28</spen>
                <a href="
https://t.me/catizenbot/gameapp?startapp=rp_2989430
                ">
https://t.me/catizenbot/gameapp?startapp=rp_2989430
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>29</spen>
                <a href="
https://t.me/catizenbot/gameapp?startapp=rp_2989431
                ">
https://t.me/catizenbot/gameapp?startapp=rp_2989431
                    </a>
            </p>
            <p>
                <spen style={{marginRight: '5px'}}>30</spen>
                <a href="
https://t.me/catizenbot/gameapp?startapp=rp_2999612
                ">
https://t.me/catizenbot/gameapp?startapp=rp_2999612
                    </a>
            </p>
            
            {/* Hotcoin */}
            <h1 style={{color: 'red'}}>Hot</h1>
            <h3>Channel</h3>
            <a href="https://t.me/+XagtIQv_ALkwODA1">
                https://t.me/+XagtIQv_ALkwODA1
            </a>
            <h3>Referral</h3>
            <a href='https://t.me/herewalletbot/app?startapp=1571600'>
                https://t.me/herewalletbot/app?startapp=1571600
            </a>
            <h3>Village</h3>
            <a href="https://t.me/herewalletbot/app?startapp=village36699">
                https://t.me/herewalletbot/app?startapp=village36699
            </a>
            {/* <h3>Board</h3>
            <a href="http://hot-refer.infinityfreeapp.com/ref/">
                게시판
            </a> */}
            
            {/* TapSwap */}
            <h1 style={{color: 'purple'}}>TapSwap</h1>
            <h3>Referral</h3>
            <a href='https://t.me/tapswap_bot?start=r_6026855291'>
                https://t.me/tapswap_bot?start=r_6026855291
            </a>

            {/* <h1 style={{color: '#D4AF37'}}>Not</h1>
            <h3>Referral</h3>
            <a href='https://t.me/notcoin_bot?start=rp_8939936'>
                https://t.me/notcoin_bot?start=rp_8939936 
            </a>
            <h3>Gift 1,000,000</h3>
            <a href='https://t.me/notcoin/188'>
                https://t.me/notcoin/188
            </a>
            <div style={{margin:'20px 0 0 20px'}}></div> */}

            {/* <h1 style={{color: 'green'}}>xBlast</h1>
            <h3>Referral</h3>
            <a href='https://t.me/XBlastAppBot/App?startapp=VDOYL8xASFK42R2'>
                https://t.me/XBlastAppBot/App?startapp=VDOYL8xASFK42R2
            </a> */}
        </div>
    );
};

export default Test;